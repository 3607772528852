footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: var(--peach-color);
  font-family: var(--neue-family);
  color: white;
  max-width: var(--max-width);
  margin: var(--centered);
  height: 2rem;

  @media (width >= 600px) {
    height: 3rem;
  }
}

.footer-text {
  font-size: .75rem;

  @media (width >= 600px) {
    font-size: 1rem;
  }
}

.footer-image {
  width: 1.25rem;
  cursor: pointer;
  margin-left: 1vw;

  @media (width >= 600px) {
    width: 2rem;
  }
}