header {
  z-index: 2;
  max-width: var(--max-width);
  margin: var(--centered);
}

.nav-link, .logo {
  font-family: var(--condensedblack-family);
  font-size: 1.25rem;
  color: var(--peach-color);
  text-decoration: none;
  -webkit-text-stroke: 1px black;
  filter: var(--dropshadow);

  @media (width >= 590px){
    font-size: 1.75rem;
  }
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--green-color);
  height: 2.5rem;
  padding: 0 2rem;
  filter: var(--dropshadow);

  @media (width >= 590px) {
    height: 3rem;
  }
}

.logo {
  font-size: 1.5rem;
  font-family: var(--neue-family);

  @media (width >= 590px){
    font-size: 2rem;
  }
}

.nav-button {
  display: flex;
  background-color: transparent;
  border: none;
  border-radius: 25%;
  cursor: pointer;
  filter: var(--dropshadow);
  margin-right: -.75rem;

  @media (width >= 590px){
    display: none;
  }
}

.nav-button-image {
  width: 2.25rem;
  height: 2.25rem;
}

.nav {
  display: none;
}

@media (width >= 590px){
  .nav {
    display: flex;
    gap: 1rem;
    list-style-type: none;
  }
}

.mobile-closed {
  display: none;
}

.mobile-open {
  display: block;
  list-style-type: none;
  text-align: right;
  padding-right: 1.75rem;
  margin-top: 0;

  @media (width >= 590px){
    display: none;
  }
}

.mobile-active {
  position: absolute;
  left: 0;
  right: 0;
}