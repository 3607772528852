.projects {
  background-image: linear-gradient(var(--yellow-color), var(--purple-color), var(--purple-color), var(--green-color));
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  font-family: var(--condensedblack-family);
  z-index: -1;
  max-width: var(--max-width);
  margin: var(--centered);
}

.project-container {
  transform: skew(-4deg, -15deg);
  background-color: var(--peach-color);
  width: 80vw;
  z-index: 1;
  filter: var(--dropshadow);
  max-width: 1000px;
}

.projects-tray {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 70vw;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  padding: 1.5vw;
  margin-bottom: 12vw;

  @media (width >= 1100px) {
    width: 51rem;
  }
}

.projects-tray-button {
  font-family: var(--condensedblack-family);
  background-color: transparent;
  border: none;
  font-size: 1rem;
  padding-bottom: 1vw;
  cursor: pointer;
  color: black;

  @media (width >= 600px) {
    font-size: 2rem;
  }
}

.projects-tray-button:disabled {
  cursor: default;
  color: rgb(0 0 0 / 40%);
}

.projects-title {
  transform: skew(4deg, 15deg);
  text-align: right;
  font-size: 4rem;
  margin: 2rem 1rem;
  -webkit-text-stroke: 2px black;
  color: white;
  margin-bottom: 0;

  @media (width >= 600px) {
    font-size: 7rem;
  }

  @media (width >= 800px) {
    font-size: 8rem;
  }

  @media (width >= 900px) {
    margin-bottom: -3vw;
  }
}

.projects-carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: skew(4deg, 15deg);
}

.project-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.project-card-link {
  text-decoration: none;
  color: black;
}

.project-image {
  width: 15vw;
  max-width: 250px;

  @media (width >= 600px) {
    width: 18vw;
  }
}

.project-title {
  margin: 0;
  font-size: .5rem;
  text-align: center;
  width: calc(100% - .25rem);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (width >= 600px) {
    font-size: 1rem;
  }

  @media (width >= 800px) {
    font-size: 1.25rem;
  }
}

.hero-project-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: skew(4deg, 15deg);
  font-family: var(--neue-family);
}

@media (width >= 900px) {
  .hero-project-container {
    flex-direction: row;
    gap: 1vw;
    margin-bottom: 2vw;
    margin-left: 1.75vw;
  }
}

.hero-project-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero-project-image {
  width: 65vw;
  transform: skew(-4deg, 0deg);
  border: 1px solid black;
  cursor: pointer;
  max-width: 600px;
  margin-top: 20px;
}

@media (width >= 900px) {
  .hero-project-image {
    width: 500px;
    transform: skew(0deg, 0deg);
    margin-top: 40px;
  }
}

.hero-project-image-button {
  font-family: var(--neue-family);
  font-size: .75rem;
  margin: 0;
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: black;

  @media (width >= 600px) {
    font-size: 1rem;
  }
}

.hero-project-info-container {
  width: 97%;

  @media (width >= 1000px) {
    margin-top: -20px;
  }
}

.hero-project-key {
  font-size: .75rem;
  margin-bottom: -1.5vw;

  @media (width >= 600px) {
    font-size: 1rem;
  }

  @media (width >= 1100px) {
    font-size: 1.25rem;
    margin-bottom: -1.1rem;
  }
}

.hero-project-value {
  margin-left: .5vw;
  margin-right: 2vw;
  font-size: .55rem;
  margin-bottom: -.75vw;

  @media (width >= 600px) {
    font-size: 1.1rem;
  }

  @media (width >= 1100px) {
    margin-left: .25vw;
    margin-right: 1.75vw;
    font-size: 1.1rem;
  }
}

.hero-project-value-link {
  font-size: .55rem;
  color: black;

  @media (width >= 600px) {
    font-size: 1.1rem;
  }

  @media (width >= 1100px) {
    font-size: 1.1rem;
    color: black;
  }
}

.image-viewer-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 60%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 5;
}

.image-viewer-container {
  display: flex;
  background-color: white;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 90vw;
  border: 1px solid black;
  filter: drop-shadow(var(--dropshadow));
  height: 45vh;
  max-width: 1000px;
  margin-top: -60px;
  margin-left: -20px;

  @media (width >= 600px) {
    margin-left: 0;
    height: 90vh;
  }
}

.image-viewer-hero-container, .image-viewer-tray {
  display: flex;
}

.image-viewer-close-container {
  display: flex;
}

.image-viewer-hero-container {
  margin-top: 2vw;
  flex-direction: column;
  align-items: center;
}

.image-viewer-close {
  display: none;
}

@media (width >= 600px) {
  .image-viewer-close {
    position: relative;
    display: block;
    width: 3rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
    bottom: 0;
    left: 38vw;
  }
}

@media (width >= 1230px) {
  .image-viewer-close {
    left: 33vw;
  }
}

.image-viewer-hero-image {
  border: 1px solid black;
  width: 70vw;
  max-width: 700px;

  @media (width >= 570px) {
    width: 55vw;
    max-width: 700px;
  }
}

.image-viewer-hero-description {
  font-size: .75rem;
  font-family: var(--neue-family);

  @media (width >= 600px) {
    font-size: 1rem;
  }

  @media (width >= 800px) {
    font-size: 1.15rem;
  }
}

.image-viewer-tray {
  width: 65vw;
  justify-content: space-around;
  margin-bottom: 2vw;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  max-width: 700px;
}

.image-viewer-tray-image {
  width: 12vw;
  padding: 1vw 0;
  cursor: pointer;
  max-width: 125px;
}

