.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  top: 4rem;
  background-color: var(--yellow-color);
  z-index: -1;
  max-width: var(--max-width);
  margin: var(--centered);
}

.me {
  background-color: var(--peach-color);
  border: 1px solid black;
  border-radius: 50%;
  width: 25%;
  max-width: 250px;
  height: auto;
  margin: .5rem 0;
}

.info-container {
  background-color: var(--green-color);
  width: 80vw;
  transform: skew(3deg, 12deg);
  filter: var(--dropshadow);
  z-index: 1;
  max-width: 1000px;
}

.hello {
  font-family: var(--condensedblack-family);
  color: white;
  font-size: 1rem;
  padding: 0 1rem;
  margin-top: -.25rem;
  transform: skew(-3deg, 0deg);

  @media (width >= 590px){
    font-size: 1.5rem;
  }

  @media (width >= 670px) {
    font-size: 2rem;
  }
}

span {
  font-family: var(--neue-family);
  font-size: 1.35rem;
  color: var(--peach-color);
  -webkit-text-stroke: 1px black;

  @media (width >= 590px){
    font-size: 2rem;
  }

  @media (width >= 670px) {
    font-size: 2.5rem;
  }
}

.about {
  font-family: var(--neue-family);
  color: white;
  font-size: .75rem;
  padding-top: 3vw;

  @media (width >= 590px){
    font-size: 1rem;
  }

  @media (width >= 670px) {
    font-size: 1.5rem;
  }
}

.bio-container {
  margin: 2.5rem 1rem 1rem;
  transform: skew(-3deg, -12deg);

  @media (width >= 670px){
    .languagelink {
      width: 2.5rem;
    }
  }
}

.languagelink {
  width: 1.5rem;
  height: auto;
  margin: 0 .5rem 0 0;
  cursor: pointer;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  margin-right: 1.75rem;
  filter: var(--dropshadow);
  padding-bottom: 10vw;
}

.socials-container {
  display: flex;
  flex-direction: column;
  text-align: right;
  transform: skewX(3deg);
  margin-left: -1rem;
}

.socials-link {
  font-family: var(--condensedblack-family);
  font-size: 1rem;
  color: black;
  background-color: var(--peach-color);
  transform: skewX(-3deg);
  padding: .75vw 4vw;
  outline: 1px solid black;
  margin-top: .75rem;
  text-decoration: none;
}

@media (width >= 670px) {
  .socials-link {
    font-size: 1.5rem;
  }
}

.reachout-button {
  background-color: var(--purple-color);
  border-radius: 100px;
  color: black;
  border: none;
  font-family: var(--condensedblack-family);
  font-size: 1rem;
  padding: .75rem 1.5rem;
  cursor: pointer;
  text-decoration: none;

  @media (width >= 670px) {
    font-size: 1.25rem;
    padding: 1.25rem 2rem;
    margin-right: 2rem;
  }

  @media (width >= 1000px) {
    font-size: 1.5rem;
    padding: 1.5rem 2.5rem;
    margin-right: 5rem;
  }
}