.reach-out {
  background-color: var(--green-color);
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  position: inherit;
  z-index: -1;
  max-width: var(--max-width);
  margin: var(--centered);
}

.reach-out-container {
  transform: skew(-10deg, 5deg);
  background-color: var(--purple-color);
  width: 80vw;
  z-index: 0;
  font-family: var(--condensedblack-family);
  margin-bottom: 3vw;
  filter: var(--dropshadow);
  max-width: 950px;
}

.reach-out-info {
  transform: skew(10deg, -5deg);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reach-out-header {
  color: white;
  text-align: center;
  -webkit-text-stroke: 2px black;
  font-size: 3.5rem;
  margin-bottom: 0;
  margin-top: 10vw;

  @media (width >= 600px) {
    font-size: 6rem;
  }

  @media (width >= 900px) {
    font-size: 8.25rem;
  }
}

.reach-out-form {
  width: 60vw;
  font-size: .75rem;
  margin-bottom: 17vw;

  @media (width >= 600px) {
    font-size: 1rem;
  }

  @media (width >= 900px) {
    font-size: 2rem;
    width: 690px;
  }
}

.reach-out-two-input {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.form-stacked-container {
  display: flex;
  flex-direction: column;
  font-size: .75rem;

  @media (width >= 600px) {
    font-size: 1rem;
  }
}

.form-meeting-container {
  display: flex;
  justify-content: space-between;
}

.form-label {
  margin-bottom: 1vw;
}

.long-input, .small-input, .form-textarea {
  background-color: var(--yellow-color);
  border: 2px solid black;
  padding: .5vw;
  margin-bottom: 2vw;
  font-family: var(--neue-family);
  font-size: .75rem;

  @media (width >= 600px) {
    font-size: 1rem;
  }
}

.long-input {
  width: auto;
}

.small-input {
  width: 25vw;

  @media (width >= 600px) {
    max-width: 320px;
  }
}

.form-textarea {
  height: 8vw;;
}

.form-meeting-message, .form-meeting-link {
  margin-top: -1vw;
  font-size: .65rem;

  @media (width >= 600px) {
    font-size: 1rem;
  }
}

.form-meeting-link {
  color: white;
}

.submit-button {
  border: none;
  cursor: pointer;
  appearance: none;
  background-color: inherit;
  transition: transform .25s ease-out;

  &:hover {
    transform: translate(1vw, -.5vw);
  }
}

.submit-image {
  width: 17vw;
  max-width: 200px;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 60%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.modal-header {
  margin-bottom: 0;
}

.modal-dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: var(--neue-family);
  height: 20vh;
  border: 2px solid black;
  filter: var(--dropshadow);
}

.modal-button {
  border: 1px solid black;
  border-radius: 2vw;
  background-color: var(--purple-color);
  filter: var(--dropshadow);
  padding: 1vw 2vw;
  margin-bottom: 1rem;
}